import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
import data from '@modules/transparency_dash/data/publicData.json';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const StatusBanner = makeShortcode("StatusBanner");
const Flex = makeShortcode("Flex");
const TDStat = makeShortcode("TDStat");
const Box = makeShortcode("Box");
const Aligner = makeShortcode("Aligner");
const Button = makeShortcode("Button");
const Tout = makeShortcode("Tout");
const List = makeShortcode("List");
const Process = makeShortcode("Process");
const Callout = makeShortcode("Callout");
const Link = makeShortcode("Link");
const Checklist = makeShortcode("Checklist");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <StatusBanner mdxType="StatusBanner">
      <p>{`We are currently funding `}<strong parentName="p">{`virtual meetups only`}</strong>{`.`}</p>
    </StatusBanner>
    <h1>{`Meetup Funding`}</h1>
    <p>{`The MakerDAO Core Community Development Initiative supports both virtual and physical meetups.      `}</p>
    <Flex sx={{
      alignItems: 'center',
      justifyContent: 'space-evenly'
    }} mdxType="Flex">
      <TDStat data={data.grants.totalMoneyAwarded} mdxType="TDStat">
Dai
        <p>{`Awarded`}</p>
      </TDStat>
      <TDStat data={data.grants.totalMoneyDispersed} mdxType="TDStat">
Dai
        <p>{`Dispersed`}</p>
      </TDStat>
      <TDStat data={data.grants.totalMoneyAwarded / data.acceptedProjects.length} mdxType="TDStat">
Average
        <p>{`Dai Award`}</p>
      </TDStat>
      <TDStat alt data={data.grants.applicationsSubmitted} mdxType="TDStat">
Grant Applications
        <p>{`Submitted`}</p>
      </TDStat>
    </Flex>
    <Box sx={{
      padding: 5
    }} mdxType="Box">
      <Aligner center mdxType="Aligner">
        <Button to="https://airtable.com/shr4HOtcZ8o3VZmek" mdxType="Button">Apply for Meetup Funding</Button>
      </Aligner>
    </Box>
    <p>{`We fund meetups worldwide that support introducing newcomers to the blockchain, Ethereum, DeFi, and MakerDAO.`}</p>
    <p>{`Since February 2018, Maker's Grant Program has distributed over 40,000 Dai to 100+ meetups in 33 countries supporting more than 5,600 attendees.`}</p>
    <Tout mdxType="Tout">
      <Box mdxType="Box">
        <h3>{`What We Do Fund`}</h3>
        <List mdxType="List">
          <p>{`Honorarium for Organizers and Speakers`}</p>
          <p>{`Space Rentals`}</p>
          <p>{`Equipment Rentals`}</p>
          <p>{`Food | Drinks`}</p>
        </List>
      </Box>
      <Box mdxType="Box">
        <h3>{`What We Don't Fund`}</h3>
        <List mdxType="List">
          <p>{`Alcoholic Beverages | Bar Tabs`}</p>
          <p>{`Burner Wallet Speakeasy Events`}</p>
          <p>{`Dai | xDai Airdrops`}</p>
          <p>{`Banners | T-shirts | Paid Marketing`}</p>
        </List>
      </Box>
    </Tout>
    <h2>{`Get Funded`}</h2>
    <blockquote>
      <h4 parentName="blockquote"><strong parentName="h4">{`Looking to fund a larger event?`}</strong></h4>
      <p parentName="blockquote">{`Reach out to us at: `}<a parentName="p" {...{
          "href": "mailto:events@makerdao.com"
        }}>{`events@makerdao.com`}</a></p>
    </blockquote>
    <p>{`Whether this is your first meetup or if you're an experienced organizer, please follow the steps below to apply for funding.`}</p>
    <p>{`After submitting your application, please allow three to five business days for a response.`}</p>
    <Process mdxType="Process">
      <Box mdxType="Box">
        <h2>{`Create an Invoice`}</h2>
        <p>{`You’ll need an invoice link to include in the application form.`}</p>
        <p>{`You’ll need the payer address: `}<inlineCode parentName="p">{`0xF563f100df419CCDe59BFBE0692fc4c5bFe01706`}</inlineCode></p>
        <p><a parentName="p" {...{
            "href": "https://app.request.network/#/"
          }}>{`Create a Request Network Invoice`}</a></p>
      </Box>
      <Box mdxType="Box">
        <h2>{`Submit an Application`}</h2>
        <p>{`This form gives us the information we'll need to decide on your funding request.`}</p>
        <p>{`Please note, whether you're collecting additional sponsorship money from another organization, or if you plan to charge an entry fee.`}</p>
        <p><a parentName="p" {...{
            "href": "https://airtable.com/shr4HOtcZ8o3VZmek"
          }}>{`Application Form`}</a></p>
        <p><strong parentName="p">{`Email responses will inform applicants of their applications' status, regardless of whether it is accepted or rejected.`}</strong></p>
        <Callout mdxType="Callout">
          <p>{`For help preparing your meetup and answering the questions in the form, please check out our hosting guides:`}</p>
          <List mdxType="List">
            <Link to="/contribute/meetups/getting-started-guide" mdxType="Link">
              <p><strong parentName="p">{`Getting Started`}</strong></p>
              <p>{`The basics for hosting a meetup. Start here if it’s your first time hosting.`}</p>
            </Link>
            <Link to="/contribute/meetups/event-formats-guide" mdxType="Link">
              <p><strong parentName="p">{`Event Format`}</strong></p>
              <p>{`How to choose the best format for your meetup based on your audience.`}</p>
            </Link>
            <Link to="/contribute/meetups/budget-guide" mdxType="Link">
              <p><strong parentName="p">{`Budget`}</strong></p>
              <p>{`How to budget effectively to make the most of the meetup costs.`}</p>
            </Link>
            <Link to="/contribute/meetups/promotion-guide" mdxType="Link">
              <p><strong parentName="p">{`Promotion`}</strong></p>
              <p>{`How and where to promote your meetup.`}</p>
            </Link>
          </List>
        </Callout>
      </Box>
      <Box mdxType="Box">
        <h3>{`During the Event`}</h3>
        <p>{`If we fund your meetup, we’ll ask you to speak briefly about Community Development at MakerDAO.`}</p>
        <Checklist mdxType="Checklist">
          <p>{`Direct new users to the `}<a parentName="p" {...{
              "href": "/content/en/index/"
            }}>{`Community Portal.`}</a></p>
          <p>{`Outline the different ways to contribute to MakerDAO.`}</p>
          <p>{`Mention that Dai bounty awards vary by type of contribution.`}</p>
          <p>{`Record and upload presentations to provide a helpful resource for others.`}</p>
        </Checklist>
      </Box>
      <Box mdxType="Box">
        <h3>{`After the Event`}</h3>
        <p>{`Please fill out our Feedback Form. It helps us track metrics and document the media and resources produced for these events.`}</p>
        <p><a parentName="p" {...{
            "href": "https://airtable.com/shr6Icuj6tOy0k55l"
          }}>{`Feedback Form`}</a></p>
        <p><strong parentName="p">{`Grantees who do not fill out this form will not be eligible for future funding.`}</strong></p>
      </Box>
    </Process>
    <h3>{`Support`}</h3>
    <List mdxType="List">
      <p>{`Check out our `}<a parentName="p" {...{
          "href": "/work-with-us/meetups/"
        }}>{`Community Guides`}</a>{` for help planning a successful meetup.`}</p>
      <p>{`Reach out to `}<a parentName="p" {...{
          "href": "mailto:meetups@makerdao.com"
        }}>{`meetups@makerdao.com`}</a>{` for more information.`}</p>
      <p>{`Ask us questions in our `}<a parentName="p" {...{
          "href": "https://chat.makerdao.com/channel/community-development"
        }}>{`Community Chat.`}</a></p>
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      